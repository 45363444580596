/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~bootstrap-icons/font/bootstrap-icons.css";
/* @import "~bootstrap/scss/bootstrap.scss"; */
/* @import '@angular/material/*'; */
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "https://cdnjs.cloudflare.com/ajax/libs/prism/1.16.0/themes/prism.css";

.mat-mdc-tab-group.mat-primary .mdc-tab-indicator__content--underline,
.mat-tab-nav-bar.mat-primary .mdc-tab-indicator__content--underline {
  background-color: #fec40d;
  border-color: #fec40d !important;
  z-index: 3 !important;
  height: 2px;
}

/*부트스트랩 그리드 safari 대응*/
.row:before,
.row:after {
  display: inline-block !important;
}

::selection {
  background-color: rgba(254, 198, 13, 0.7);
}

* {
  font-family: noto sans kr;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
   font-family: "Noto Sans KR";
}
